import axios from "axios";
import headers from '../authHeaders';

export interface MemberInfo {
	qhid: number
	groupId: number
	groupDescription: string
	memberId: string
	sex: "M" | "F" | "U"
	email: string
	firstName: string
	middleInitial: string
	lastName: string
	dob: string
	homePhone: string
	mobilePhone: string
	workPhone: string
	coverageId: number
}

export interface SearchRequest {
	firstname: string | null
	middlename: string | null
	lastname: string | null
	dob: string | null
	memberid: string | null
	action: string | null
}

export interface CoverageInfo {
	planOptionName: string
	effectiveDate: Date
	termDate: Date
}


export default { 
	async get(qhid: number): Promise<MemberInfo> {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/member/${qhid}/`,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async search(data: SearchRequest): Promise<number> {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/member/search/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async getBenefitSummary(qhid: number): Promise<string> {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/member/benefitssummary?qhid=' + qhid,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async getCoverageInfo(qhid: number): Promise<CoverageInfo[]> {
		const response = await axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/member/${qhid}/coverage`,
			headers: await headers.getAuthHeaders()
		})
		return (response.data as any[]).map((c) => {
			return {
				planOptionName: c.planOptionName as string,
				effectiveDate: new Date(c.effectiveDate),
				termDate: new Date(c.termDate)
			}
		})
	}
}
