import axios from "axios";
import headers from '../authHeaders';
import ResourceCache from '../resourceCache.js';

const cache = new ResourceCache();

export default { 
  async getResource(key: string): Promise<string> {
    if (cache.exists(key)) {
      return Promise.resolve(cache.get(key));
    }

    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/resource/' + key + '/',
      headers: await headers.getAuthHeaders()
    }).then(response => {
      cache.add(key, response.data);
      return response.data;
    });
  },
  async getResourceForMember(key: string, qhid: number): Promise<string> {
    const cacheKey = `${key}_${qhid}`;
    if (cache.exists(cacheKey)) {
      return Promise.resolve(cache.get(cacheKey));
    }

    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/resource/member/' + key + '/' + qhid + '/',
      headers: await headers.getAuthHeaders()
    }).then(response => {
      cache.add(key, response.data);
      return response.data;
    });
  }
}
