import axios from "axios";
import headers from '../authHeaders';

export default { 
	async getAccount(id) {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/account/' + id + '/',
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async getAccounts(search) {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/accounts/search/',
			data: search,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async getAssociations(search) {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/associations/search/',
			data: search,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async saveAccount(changes) {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/account/',
			data: changes,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async saveAssociations(request) {
		return axios({
			method: "put",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/associations/',
			data: request,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async saveAssociationStatusChanges(changes) {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/associations/',
			data: changes,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
  async adminLogin(username, password) {
      return axios({
        method: "post",
        url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/login/',
        data: { 'Username': username, 'Password': password }
      }).then(response => {
        return response.data;
      });
  },
  async beginImpersonation(helperId) {
      return axios({
        method: "post",
        url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/admin/impersonate/begin/${helperId}/`,
				headers: await headers.getAuthHeaders()
      }).then(response => {
        return response.data;
      });
  },
  async endImpersonation() {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/impersonate/end/',
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
  async setTemporaryPassword(request) {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/account/password/',
			headers: await headers.getAuthHeaders(),
			data: request
		}).then(response => {
			return response.data;
		});
	},
	async getAdminDashboardItems() {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/dashboard/',
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async getAdminDashboardItemsAutoApproved() {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/admin/dashboardAutoApproved/',
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	}
}
