import steps from '@/services/registrationSteps.js';
import accountService from '@/services/api/account.js';

const flowName = 'Registration';

const state = {
  step: null,
  data: null,
  mfaMethod: null
};

const getters = {
  step(state) {
    return state.step;
  },
  data(state) {
    // return copy of object
    return { ...state.data }
  },
  mfaMethod(state) {
    return state.mfaMethod;
  }
};
const actions = {
  initialize({ commit }) {
    return new Promise(resolve => {
      commit('initialize');
      resolve();
    });
  },
  setUserInfo({ commit, getters, rootGetters }, {
    firstName,
    lastName,
    title,
    email,
    phone,
    phoneExt,
    mobile,
    securityQuestion1,
    securityQuestion2,
    securityQuestion3,
    securityAnswer1,
    securityAnswer2,
    securityAnswer3
  }) {
    return new Promise((resolve, reject) => {
      try {
        commit('setMfaMethod', null);
        commit('setUserInfo', {
          firstName,
          lastName,
          email,
          title,
          phone,
          phoneExt,
          mobile,
          securityQuestion1,
          securityQuestion2,
          securityQuestion3,
          securityAnswer1,
          securityAnswer2,
          securityAnswer3
        });

        if (rootGetters['settings/registrationUsesSms'] && getters.data.mobile) {
          commit('setStep', steps.mfaMethod);
          resolve(steps.mfaMethod);

          return;
        }

        accountService.sendMfaEmail(
          getters.data.email,
          getters.data.email, 
          flowName
        ).then(() => {
          commit('setStep', steps.mfa);
          commit('setMfaMethod', 'email');
          resolve(steps.mfa);
        }).catch(() => {
          reject();
        });
      } catch {
        reject();
      }
    });
  },
  chooseMfaMethod({ commit, getters }, mfaMethod) {
    return new Promise((resolve, reject) => {
      commit('setMfaMethod', mfaMethod);
      
      const action = mfaMethod === 'mobile'
        ? () => accountService.sendMfaText(getters.data.email, getters.data.mobile, flowName)
        : () => accountService.sendMfaEmail(getters.data.email, getters.data.email, flowName);

      action().then(() => {
        commit('setStep', steps.mfa);
        resolve(steps.mfa);
      }).catch(() => {
        reject();
      });
    });
  },
  submitMfa({ commit, getters }, code) {
    return new Promise((resolve, reject) => {
      accountService.verifyMfa(getters.data.email, code, flowName).then(response => {
        if (response.error) {
          reject(true);
          return;
        }

        commit('setStep', steps.providerInfo);
        resolve(steps.providerInfo);
      }).catch(() => {
        reject();
      });
    });
  },
  setProviderInfo({ commit }, {
    providerFirstName,
    providerLastName,
    facility,
    taxId,
    npi,
    facilityPhone,
    facilityPhoneExt,
    address1,
    address2,
    city,
    state,
    zipCode,
    termsAccepted
  }) {
    return new Promise((resolve, reject) => {
      try {
        commit('setProviderInfo', {
          providerFirstName,
          providerLastName,
          facility,
          taxId,
          npi,
          facilityPhone,
          facilityPhoneExt,
          address1,
          address2,
          city,
          addressState : state,
          zipCode,
          termsAccepted
        });

        commit('setStep', steps.setPassword);
        resolve(steps.setPassword);
      } catch {
        reject();
      }
    });
  },
  finalize({ commit, getters }, password) {
    return new Promise((resolve, reject) => {
      commit('setPassword', password);

      const data = getters.data;
      accountService.register({
        firstname: data.firstName,
        lastname: data.lastName,
        title: data.title,
        email: data.email,
        phone: data.phone,
        phoneExt: data.phoneExt,
        mobile: data.mobile,
        securityQuestion1: data.securityQuestion1,
        securityQuestion2: data.securityQuestion2,
        securityQuestion3: data.securityQuestion3,
        securityAnswer1: data.securityAnswer1,
        securityAnswer2: data.securityAnswer2,
        securityAnswer3: data.securityAnswer3,
        providerFirstName: data.providerFirstName,
        providerLastName: data.providerLastName,
        facility: data.facility,
        taxId: data.taxId,
        npi: data.npi,
        facilityPhone: data.facilityPhone,
        facilityPhoneExt: data.facilityPhoneExt,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
        terms: data.termsAccepted,
        password: data.password
      }).then(data => {
        if (!data) {
          reject(true);
          return;
        }

        commit('initialize');
        resolve(data);
      }).catch(() => {
        reject(true);
      });
    });
  }
};

const mutations = {
  initialize(state) {
    state.mfaMethod = null;
    state.step = steps.begin;

    state.data = {
      firstName: null,
      lastName: null,
      email: null,
      title: null,
      phone: null,
      phoneExt: null,
      mobile: null,
      securityQuestion1: null,
      securityQuestion2: null,
      securityQuestion3: null,
      securityAnswer1: null,
      securityAnswer2: null,
      securityAnswer3: null,
      termsAccepted: null,
      providerFirstName: null,
      providerLastName: null,
      facility: null,
      taxId: null,
      npi: null,
      facilityPhone: null,
      facilityPhoneExt: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zipCode: null,
      password: null
    };
  },
  setStep(state, step) {
    state.step = step;
  },
  setUserInfo(state, {
    firstName,
    lastName,
    email,
    title,
    phone,
    phoneExt,
    mobile,
    securityQuestion1,
    securityQuestion2,
    securityQuestion3,
    securityAnswer1,
    securityAnswer2,
    securityAnswer3
  }) {
    state.data.firstName = firstName;
    state.data.lastName = lastName;
    state.data.email = email;
    state.data.title = title;
    state.data.phone = phone;
    state.data.phoneExt = phoneExt;
    state.data.mobile = mobile;
    state.data.securityQuestion1 = securityQuestion1;
    state.data.securityQuestion2 = securityQuestion2;
    state.data.securityQuestion3 = securityQuestion3;
    state.data.securityAnswer1 = securityAnswer1;
    state.data.securityAnswer2 = securityAnswer2;
    state.data.securityAnswer3 = securityAnswer3;
  },
  setMfaMethod(state, method) {
    state.mfaMethod = method;
  },
  setProviderInfo(state, {
    providerFirstName,
    providerLastName,
    facility,
    taxId,
    npi,
    facilityPhone,
    facilityPhoneExt,
    address1,
    address2,
    city,
    addressState,
    zipCode,
    termsAccepted
  }) {
    state.data.providerFirstName = providerFirstName;
    state.data.providerLastName = providerLastName;
    state.data.facility = facility;
    state.data.taxId = taxId;
    state.data.npi = npi;
    state.data.facilityPhone = facilityPhone;
    state.data.facilityPhoneExt = facilityPhoneExt;
    state.data.address1 = address1;
    state.data.address2 = address2;
    state.data.city = city;
    state.data.state = addressState;
    state.data.zipCode = zipCode;
    state.data.termsAccepted = termsAccepted;
  },
  setPassword(state, password) {
    state.data.password = password;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}