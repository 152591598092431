const dateFormatter = new Intl.DateTimeFormat('en-US', {timeZone: 'UTC'})
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})
const numberFormatter = new Intl.NumberFormat('en-US')

export default {
    formatDate(date: string | number | Date | null | undefined) {
        if (date === undefined || date === null) return '';

        date = new Date(date);

        return dateFormatter.format(date);
    },
    formatAddress(address1: string, city: string, state: string, zipCode: string | number) {
        let address = '';

        if (address1 && address1.length > 0)
            address = address1;

        if ((city && city.length > 0) || (city && city.length > 0) || (city && city.length > 0))
            address = address + (address.length > 0 ? '<br/>' : '') + city + ', ' + state + ' ' + zipCode;

        return address;
    },
    formatPhone(phone: string | null | undefined, phoneExt: string, sameLine: boolean) {
        if (phone === undefined || phone === null) return '';

        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1&#8209;$2&#8209;$3') + (phoneExt && phoneExt.length > 0 ? (sameLine === true ? ' ' : '<br/>') + 'Ext. ' + phoneExt : '');
	},
	formatCurrency(value: any) {
		if (typeof value !== "number") {
			return value;
		}
		
		return currencyFormatter.format(value);
	},
    formatTaxId(taxId: string | null | undefined) {
        if (!taxId) return '';
        return taxId.replace("-", "").replace(/(\d{2})(\d{7})/, '$1&#8209;$2');
	},
    padDate(value: number) {
        let paddedValue = value.toString()
        if (value < 10)
            paddedValue = '0' + paddedValue

        return paddedValue;
    },
    formatNumber: numberFormatter.format 
}