import steps from '@/services/unlockAccountSteps.js';
import accountService from '@/services/api/account.js';

const flowName = 'UnlockAccount';

const state = {
  step: null,
  data: null,
  locked: null,
  mfaMethod: null,
  staticEmail: null
};

const getters = {
  step(state) {
    return state.step;
  },
  data(state) {
    // return copy of object
    return { ...state.data }
  },
  locked(state) {
    return state.locked;
  },
  mfaMethod(state) {
    return state.mfaMethod;
  },
  staticEmail(state) {
    return state.staticEmail;
  }
};

const actions = {
  initialize({ commit }, staticEmail) {
    return new Promise(resolve => {
      commit('initialize', staticEmail);
      resolve();
    });
  },
  begin({ commit, getters }, { firstName, lastName, email }) {
    return new Promise((resolve, reject) => {
      accountService.beginUnlockAccount({
        firstName,
        lastName,
        email
      }).then(response => {
        if (response.error) {
          handleUserError({ commit, reject, getters, response });
          return;
        }

        commit('setMfaMethod', null);
        commit('setData', {
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          helperId: response.helperId,
          mobile: response.mobile,
          securityQuestion: response.securityQuestion,
          securityAnswerFormat: response.securityAnswerFormat
        });

        commit('setStep', steps.securityQuestion);
        resolve(steps.securityQuestion);
      }).catch(() => {
        reject();
      });
    });
  },
  submitAnswer({ commit, getters }, answer) {
    return new Promise((resolve, reject) => {
      accountService.submitAnswer(
        getters.data.email,
        answer,
        getters.data.securityAnswerFormat,
        flowName
      ).then(response => {
        if (response.error) {
          handleUserError({ commit, reject, getters, response });
          return;
        }

        if (getters.data.mobile) {
          commit('setStep', steps.mfaMethod);
          resolve(steps.mfaMethod);
          return;
        }

        accountService.sendMfaEmail(
          getters.data.email,
          getters.data.email, 
          flowName
        ).then(() => {
          commit('setStep', steps.mfa);
          commit('setMfaMethod', 'email');
          resolve(steps.mfa);
        }).catch(() => {
          reject();
        });
      }).catch(() => {
        reject();
      });
    });
  },
  chooseMfaMethod({ commit, getters }, mfaMethod) {
    return new Promise((resolve, reject) => {
      commit('setMfaMethod', mfaMethod);
      
      const action = mfaMethod === 'mobile'
        ? () => accountService.sendMfaText(getters.data.email, getters.data.mobile, flowName)
        : () => accountService.sendMfaEmail(getters.data.email, getters.data.email, flowName);

      action().then(() => {
        commit('setStep', steps.mfa);
        resolve(steps.mfa);
      }).catch(() => {
        reject();
      });
    });
  },
  finalize({ dispatch, commit, getters }, code) {
    return new Promise((resolve, reject) => {
      accountService.verifyMfa(getters.data.email, code, flowName).then(response => {
        if (response.error) {
          handleUserError({ commit, reject, getters, response });
          return;
        }

        accountService.finalizeUnlockAccount(getters.data.helperId).then(success => {
          if (!success) {
            reject();
            return;
          }
  
          dispatch('initialize');
          resolve();
        }).catch(() => {
          reject();
        });
      }).catch(() => {
        reject();
      });
    });
  }
};

const mutations = {
  initialize(state, staticEmail) {
    state.locked = false;
    state.mfaMethod = null;
    state.step = steps.begin;
    state.staticEmail = staticEmail
      ? staticEmail
      : null;

    state.data = {
      firstName: null,
      lastName: null,
      email: null,
      mobile: null,
      helperId: null,
      securityQuestion: null
    };
  },
  setStep(state, step) {
    state.step = step;
  },
  setData(state, data) {
    state.data = data;        
  },
  setLocked(state, locked) {
    state.locked = locked;
  },
  setMfaMethod(state, method) {
    state.mfaMethod = method;
  }
};

function handleUserError({ commit, getters, reject, response }) {
  if (getters.locked !== response.attemptInfo.locked) {
    commit('setLocked', response.attemptInfo.locked);
  }

  reject(response);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}