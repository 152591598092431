const mixin = {
  data() {
    return {
      step: 1,
      error: false,
      loading: false,
      mounted: false,
      attemptInfo: null,
      routeBaseName: ''
    }
  },
  computed: {
    remainingAttempts() {
      if (!this.attemptInfo) {
        return null;
      }

      return this.attemptInfo.maxAttempts - this.attemptInfo.attempts;
    },
    attemptsMessage() {
      if (!this.remainingAttempts) {
        return null;
      }

      let attemptText = 'attempt';
      if (this.remainingAttempts > 1) {
        attemptText += 's';
      }

      return `${this.remainingAttempts} ${attemptText} remaining`;
    },
    hasError() {
      return this.locked;
    },
    fieldsDisabled() {
      return this.loading || this.locked;
    },
    submitDisabled() {
      return this.$v.$invalid || this.fieldsDisabled;
    }
  },
  methods: {
    onMount() {},
    onSubmit() {},
    submit() {
      this.error = false;
      this.loading = true;
      this.onSubmit().then((step) => {
        if (step && step !== this.step) {
          this.$router.push({ name: `${this.routeBaseName}${step}` });
        } else {
          this.loading = false;
        }
      }).catch(response => {
        this.loading = false;

        if (response) {
          this.error = true;
          this.attemptInfo = response.attemptInfo
            ? response.attemptInfo
            : this.attemptInfo;
        } else {
          this.$toasted.global.qh_toast_error();
        }
      });
    }
  },
  validations: {},
  mounted() {
    if (this.currentStep < this.step) {
      this.$router.replace({
        name: `${this.routeBaseName}${this.currentStep}`
      });
      
      return;
    }

    this.error = this.hasError;
    const result = this.onMount();
    const setMounted = () => this.mounted = true;

    if (result && result.then) {
      result.then(setMounted).catch(setMounted);
    } else {
      setMounted();
    }
  }
}

export default mixin;