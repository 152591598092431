import axios from "axios";
import headers from '../authHeaders';

export default {
    async trackFeedbackModal(trackingInfo) {
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/feedbackModal/tracking/',
            headers: await headers.getAuthHeaders(),
            data: trackingInfo
        }).then(response => {
            return response.data;
        });
    },
    async getFeedbackModalOptions() {
        return axios({
            method: "get",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/feedbackModal/options/`,
            headers: await headers.getAuthHeaders()
        }).then(response => {
            return Promise.resolve(response.data);
        });
    }
}