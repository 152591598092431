
const state = {
  searchNetworks: null,
}

const actions = {
  searchNetworks( { commit }, networks) {
    commit("searchNetworks", networks);
  }
}

const mutations = {
  searchNetworks(state, value) {
    state.searchNetworks = value;
  }
}

const getters = {
  getSearchNetworks: (state) => {
    return state.searchNetworks;
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}