// This is a shortened version from auth-intake src/scripts/globals.js
  export const authTypes = {
    AERIAL_AUTH_TYPES: {
      STANDARD: { value: "MEDRRT01", text: "Standard" },
      CONCURRENT: { value: "MEDRRT03", text: "Concurrent Review" },
      RETRO: { value: "MEDRRT04", text: "Retrospective" },
      RECONSIDERSTANDARD: {
        value: "QHRECSTND",
        text: "Reconsideration Standard"
      },
      RECONSIDERCONCURRENT: {
        value: "QHRECCON",
        text: "Reconsideration Concurrent"
      },
      RECONSIDERPRED: { value: "QHRECPRED", text: "Reconsideration PreD" },
      RECONSIDERURGENT: { value: "QHRECUR", text: "Reconsideration Urgent" },
      PREDETERMINATION: { value: "QHPRED", text: "PreDetermination" },
      URGEMT: { value: "MEDRRT02", text: "Urgent" },
      POST_CLAIM: { value: "QHCLMRV", text: "Claim Review" }
    },
    PIVIT_AUTH_TYPES: {
      PREDETERMINATION: "PD",
      RETRO: "RR",
      PRECERT: "PC"
    },
    AERIAL_AUTH_SELECTIONS: [
      { value: "MEDRRT01", text: "Standard" },
      { value: "MEDRRT03", text: "Concurrent Review" },
      { value: "MEDRRT04", text: "Retrospective" },
      { value: "QHRECSTND", text: "Reconsideration Standard" },
      { value: "QHRECCON", text: "Reconsideration Concurrent" },
      { value: "QHRECPRED", text: "Reconsideration PreD" },
      { value: "QHRECUR", text: "Reconsideration Urgent" },
      { value: "MEDRRT02", text: "Urgent" },
      { value: "QHCLMRV", text: "Claim Review" },
      { value: "QHPRED", text: "PreDetermination" }
    ]
  };

  export const networkStatus = {
    networkStatus: ((statusId) => {
      if (statusId == 1) {
        return "In Network";
      }
      if (statusId == 2) {
        return "Redirected INN";
      }
      if (statusId == 3) {
        return "Redirected Better Tier";
      }
      if (statusId == 4) {
        return "Out of Network";
      }
      if (statusId == 5) {
        return "Out of Network, Pay as In Network";
      }
      if (statusId == 6) {
        return "Out of Network, Pay as In w/Exception";
      }
      if (statusId == 7) {
        return "In Network, Not Best Tier";
      }
    }
  )};