import { mapState, mapGetters } from 'vuex';

const mixin = {
  data() {
    return {
      routeBaseName: 'AccountUpdate',
      text: {
        header: 'Account Update'
      }
    }
  },
  computed: {
    ...mapState('currentUser', {
      currentUser: 'user'
    }),
    ...mapGetters('accountUpdate', {
      data: 'data',
      currentStep: 'step'
    })
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push({ name: 'Login' });
    } else if (!this.currentUser.forceAccountUpdate) {
      this.$router.push({ name: 'Home' });
    }
  }
};

export default mixin;