export default class ResourceCache {
  constructor() {
    this.cache = {};
  }

  add(key, value) {
    if (!this.exists(key)) {
      this.cache[key] = value;
    }
  }

  exists(key) {
    return key in this.cache;
  }

  get(key) {
    return this.cache[key];
  }
}
