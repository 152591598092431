import axios from "axios";
import headers from '../authHeaders';

export default {
	async getClaimYears(qhid) {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/claims/years/',
			params: { qhid },
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async search(data) {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/claims/search/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async getClaimCodes(qhid, claimNumber) {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/claims/codes/' + qhid + '/' + claimNumber,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async getClaimsExist(qhid) {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/claims/getClaimsExist/${qhid}`,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	}
}
