export default {
  begin: 1,
  securityQuestion: 2,
  mfaMethod: 3,
  mfa: 4,
  setPassword: 5,
  addMobileNumber: 6,
  setSecurityQuestions: 7,
  termsOfService: 8,
  finalize: 9
}