import Vue from 'vue'
import Vuex from 'vuex'
import settings from './modules/settings'
import currentUser from './modules/currentUser'
import registration from './modules/registration'
import accountUpdate from './modules/accountUpdate'
import forgotPassword from './modules/forgotPassword'
import unlockAccount from './modules/unlockAccount'
import searchResults from './modules/searchResults'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		settings,
		currentUser,
		registration,
		accountUpdate,
		forgotPassword,
		unlockAccount,
		searchResults
	}
});
