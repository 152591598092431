import steps from '@/services/accountUpdateSteps.js';
import accountService from '@/services/api/account.js';

const state = {
  step: null,
  data: null
};

const getters = {
  step(state) {
    return state.step;
  },
  data(state) {
    // return copy of object
    return { ...state.data }
  }
};

const actions = {
  initialize({ commit }) {
    return new Promise(resolve => {
      commit('initialize');
      resolve();
    });
  },
  setPassword({ commit }, password) {
    return new Promise((resolve, reject) => {
      try {
        commit('setPassword', password);

        commit('setStep', steps.addMobileNumber);
        resolve(steps.addMobileNumber);
      } catch {
        reject();
      }
    });
  },
  addMobileNumber({ commit }, { mobile, skipMobile }) {
    return new Promise((resolve, reject) => {
      try {
        commit('setMobile', { mobile, skipMobile });
        commit('setStep', steps.setSecurityQuestions);
        resolve(steps.setSecurityQuestions);
      } catch {
        reject();
      }
    });
  },
  setSecurityQuestions({ commit }, {
    securityQuestion1,
    securityQuestion2,
    securityQuestion3,
    securityAnswer1,
    securityAnswer2,
    securityAnswer3
  }) {
    return new Promise((resolve, reject) => {
      try {
        commit('setSecurityQuestions', {
          securityQuestion1,
          securityQuestion2,
          securityQuestion3,
          securityAnswer1,
          securityAnswer2,
          securityAnswer3
        });

        commit('setStep', steps.termsOfService);
        resolve(steps.termsOfService);
      } catch {
        reject();
      }
    });
  },
  finalize({ commit, getters }, { helperId, email }) {
    return new Promise((resolve, reject) => {
      try {
        const data = getters.data;
        accountService.updateAccount({
          accountId: helperId,
          email: email,
          mobile: data.mobile,
          password: data.password,
          securityQuestion1: data.securityQuestion1,
          securityQuestion2: data.securityQuestion2,
          securityQuestion3: data.securityQuestion3,
          securityAnswer1: data.securityAnswer1,
          securityAnswer2: data.securityAnswer2,
          securityAnswer3: data.securityAnswer3,
          termsAccepted: true
        }).then(() => {
          commit('initialize');
          resolve();
        }).catch(() => {
          reject();
        });
      } catch {
        reject();
      }
    });
  }
};

const mutations = {
  initialize(state) {
    state.step = steps.begin;
    state.data = {
      mobile: null,
      skipMobile: null,
      securityQuestion1: null,
      securityQuestion2: null,
      securityQuestion3: null,
      securityAnswer1: null,
      securityAnswer2: null,
      securityAnswer3: null
    };
  },
  setStep(state, step) {
    state.step = step;
  },
  setData(state, data) {
    state.data = data;        
  },
  setPassword(state, password) {
    state.data.password = password;
  },
  setMobile(state, { mobile, skipMobile }) {
    state.data.skipMobile = skipMobile;
    state.data.mobile = !skipMobile ? mobile : null;
  },
  setSecurityQuestions(state, {
    securityQuestion1,
    securityQuestion2,
    securityQuestion3,
    securityAnswer1,
    securityAnswer2,
    securityAnswer3
  }) {
    state.data.securityQuestion1 = securityQuestion1;
    state.data.securityQuestion2 = securityQuestion2;
    state.data.securityQuestion3 = securityQuestion3;

    state.data.securityAnswer1 = securityAnswer1;
    state.data.securityAnswer2 = securityAnswer2;
    state.data.securityAnswer3 = securityAnswer3;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}