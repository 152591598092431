import { mapState, mapGetters } from 'vuex';

const mixin = {
  data() {
    return {
      step: 1,
      steps: [
        {
          id: 'registrationStep1',
          label: 'Your Information',
          complete: false,
          stepNumbers: [1]
        },
        {
          id: 'registrationStep2',
          label: 'Verification',
          complete: false,
          stepNumbers: [2, 3]
        },
        {
          id: 'registrationStep3',
          label: 'Provider / Facility Information',
          labelHtml: 'Provider / Facility<br />Information',
          complete: false,
          stepNumbers: [4]
        },
        {
          id: 'registrationStep4',
          label: 'Create Password',
          complete: false,
          stepNumbers: [5]
        }
      ],
      routeBaseName: 'Register',
      text: {
        header: ''
      }
    }
  },
  computed: {
    ...mapState('currentUser', {
      currentUser: 'user'
    }),
    ...mapGetters('registration', {
      data: 'data',
      currentStep: 'step',
      currentMfaMethod: 'mfaMethod'
    })
  },
  mounted() {
    if (this.currentUser) {
      this.$router.push({ name: 'Home' });
      return;
    }

    const steps = this.steps.filter(
      x => x.stepNumbers[x.stepNumbers.length - 1] < this.currentStep
    );

    for (let i = 0; i < steps.length; i++) {
      steps[i].complete = true;
    }
  }
};

export default mixin;