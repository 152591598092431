import axios from "axios";
import headers from "../authHeaders";

export interface MemberNetworkData {
    qhid: number
    networkList: MemberNetwork[]
    myQHealthProviderSearchPath: string
    hasControlledNetwork: boolean
    hasUmVendor: boolean
    myQHealthRootUrl: string
}

export interface MemberNetwork {
    networkId: number
    networkName: string
    networkTypePrefix: string
    memberNetworkUrl: string
    networkUrl: string
    type: string
    isControlledNetwork: boolean
    isCareManaged: boolean
    calculatedNetworkUrl: string
}

export default {
    async getMemberNetworks(qhid: number): Promise<MemberNetworkData> {
        const response = await axios({
            method: "get",
			url: (process.env.NODE_ENV == "production" ?
                process.env.VUE_APP_EXTERNALPROVIDERS_API
                : "externalproviders")
                + `/api/network/GetMemberNetworks/${qhid}/`,
			headers: await headers.getAuthHeaders()
        });
        return response.data;
    }
}
