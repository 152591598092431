import store from '../store';

export default { 
    async getAuthHeaders() {
        const user = await store.state.currentUser;
        let userId = '';
        let token = '';
        const appIdValue = store.state.settings.data.benefitCheckerAdminApiKey;

        if(user && user.user && user.user.userName) {
            userId = user.user.userName;
        }
        if(user && user.user && user.user.token) {
            token = 'Bearer ' + user.user.token;
        }
    
        if(userId == '' && token == '') {
            return null;
        }

        return {
          'Authorization': token,
          'QH-User-Name': userId,
          'AppId': appIdValue,
          'UserId': userId,
        }
    }
}