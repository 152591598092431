import axios from "axios";
import headers from "../authHeaders";


export default {
    async getCptListByCategoryDetailId(categoryDetailId, codeType) {
        let response = await axios({
            method: "get",
			url: (process.env.NODE_ENV == "production" ?
                process.env.VUE_APP_EXTERNALPROVIDERS_API
                : "externalproviders")
                + `/api/lookup/getCptListByCategoryDetailId/${categoryDetailId}`
                + (codeType ? `?codeType=${codeType}` : ""),
			headers: await headers.getAuthHeaders()
        });
        return response.data;
    }
}