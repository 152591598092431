import settingsService from '@/services/api/setting';

const state = {
  data: {
    states: [],
    securityQuestions: {},
    icdLookupSettings: {},
    cptLookupSettings: {}
  }
};

const actions = {
  initialize ({ commit }) {
    return settingsService.getSettings().then(settings => {
      commit('setData', { data: settings });
    });
  }
};

const mutations = {
  setData (state, { data }) {
    state.data = data;    
  }
};

//Must be a comma at the end of every get statement or the value will not return, even if last statement
const getters = {
  states: (state) => state.data.states,
  securityQuestions: (state) => state.data.securityQuestions,
  icdLookupSettings: (state) => state.data.icdLookupSettings,
  cptLookupSettings: (state) => state.data.cptLookupSettings,
  googleAnalyticsKey: (state) => state.data.googleAnalyticsKey,
  internalTimeOutMinutes: (state) => state.data.internalTimeOutMinutes,
  externalTimeOutMinutes: (state) => state.data.externalTimeOutMinutes,
  externalTimeOutDialogMinutes: (state) => state.data.externalTimeOutDialogMinutes,
  enableExternalProvidersAerial: (state) => state.data.enableExternalProvidersAerial,
  externalProvidersAerialStandardWorkflowGroups: (state) => state.data.externalProvidersAerialStandardWorkflowGroups,
  externalProvidersExcludeGroupsAerialUnplannedWorkflow: (state) => state.data.externalProvidersExcludeGroupsAerialUnplannedWorkflow,
  externalProvidersAerialMaternityWorkflowGroups: (state) => state.data.externalProvidersAerialMaternityWorkflowGroups,
  externalProvidersExcludeGroupsAerialDmeWorkflow: (state) => state.data.externalProvidersExcludeGroupsAerialDmeWorkflow,
  externalProvidersEnableAuthEdits: (state) => state.data.externalProvidersEnableAuthEdits,
  externalProvidersEnableAuthEditsForNewAuthGroups: (state) => state.data.externalProvidersEnableAuthEditsForNewAuthGroups,
  registrationUsesSms: (state) => state.data.registrationUsesSms,
  externalProvidersPostAuthSubmissionGroupExclude: (state) => state.data.externalProvidersPostAuthSubmissionGroupExclude,
  enableExternalProvidersPostAuthSubmission: (state) => state.data.enableExternalProvidersPostAuthSubmission,
  enableExternalProvidersAuthOverviewTab: (state) => state.data.enableExternalProvidersAuthOverviewTab,
  isAerialOutage: (state) => state.data.isAerialOutage,
  aerialOutageMessage: (state) => state.data.aerialOutageMessage,
  externalProviderCPTCodeSearchEnabled: (state) => state.data.externalProviderCPTCodeSearchEnabled,
  enableExternalProviderSOCLinkBox: (state) => state.data.enableExternalProviderSOCLinkBox,
  externalProvidersSOCMessageRawHTML: (state) => state.data.externalProvidersSOCMessageRawHTML,
  benefitCheckerBaseUrl: (state) => state.data.benefitCheckerBaseUrl,
  benefitCheckerAdminApiKey: (state) => state.data.benefitCheckerAdminApiKey,
  externalProvidersAutoApprovalMessage: (state) => state.data.externalProvidersAutoApprovalMessage,
  globalBannerMessage: (state) => state.data.globalBannerMessage,
  enableUploadDocTabUnderManageAuth: (state) => state.data.enableUploadDocTabUnderManageAuth,
  enableNewMaternityView: (state) => state.data.enableNewMaternityView,
  newAuthorizationIntakeByGroup: (state) => state.data.newAuthorizationIntakeByGroup,
  enableBabyAuthorizationUI: (state) => state.data.enableBabyAuthorizationUI,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
