import { render, staticRenderFns } from "./ResourceViewer.vue?vue&type=template&id=7a6f4fa2&scoped=true"
import script from "./ResourceViewer.vue?vue&type=script&lang=js"
export * from "./ResourceViewer.vue?vue&type=script&lang=js"
import style0 from "./ResourceViewer.vue?vue&type=style&index=0&id=7a6f4fa2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a6f4fa2",
  null
  
)

export default component.exports