import adminService from '@/services/api/admin.js';
import accountService from '@/services/api/account.js';

const STORAGE_KEY = 'ep.currentUser';

const state = {
  user: undefined,
  temporaryMemberInfo: null,
  impersonationLoading: false,
  refererSource: null,
  currentMemberQhid: 0
}

const actions = {
  initialize({ commit }) {
    return new Promise((resolve) => {
      const userItem = localStorage.getItem(STORAGE_KEY);
      if (!userItem) {
        resolve();
        return;
      }

      const userObject = JSON.parse(userItem);
      if (!userObject) {
        resolve();
        return;
      }

      commit('changeUser', { user: userObject.user });

      if (!userObject.user) {
        resolve();
        return;
      }

      return accountService.handshake()
        .then(resolve)
        .catch(() => {
          commit('removeToken');
          resolve();
        });
    });
  },
  updateUser ({ commit }, newUser) {
    commit('changeUser', { user: newUser });
  },
  removeUser({ commit }) {
    commit('changeUser', { user: undefined });
    commit('setQhid', 0);
  },
  setTemporaryMemberInfo({ commit }, info) {
    commit("setTemporaryMemberInfo", info);
  },
  beginImpersonation ({ commit, state }, { accountId, returnTo }) {
    return new Promise((resolve, reject) => {
      commit('setImpersonationLoading', true);
      adminService.beginImpersonation(accountId).then(response => {
        const current = { ...state.user };

        current.loginId = response.loginId;
        current.accountId = response.accountId;
        current.impersonatingAs = `${response.firstName} ${response.lastName}`;
        current.returnTo = returnTo;

        commit('changeUser', { user: current });
        commit('setImpersonationLoading', false);

        resolve();
      })
      .catch(() => {
        commit('setImpersonationLoading', false);
        reject();
      });
    });
  },
  endImpersonation ({ commit, state }) {
    const returnTo = state.user.returnTo;
    commit('setImpersonationLoading', true);
    return new Promise((resolve, reject) => {
      adminService.endImpersonation().then(() => {
        const current = { ...state.user };

        current.accountId = 0;
        delete current.loginId;
        delete current.impersonatingAs;
        delete current.returnTo;

        commit('changeUser', { user: current });
        commit('setImpersonationLoading', false);

        resolve(returnTo);
      }).catch(() => {
        commit('setImpersonationLoading', false);
        reject();
      });
    });
  },
  updateTemporaryPassword ({ commit, state }, password) {
    return new Promise((resolve, reject) => {
      accountService.updateTemporaryPassword({
        helperId: state.user.accountId,
        newPassword: password
      }).then(() => {
        const current = { ...state.user };
        current.hasTemporaryPassword = false;
        commit('changeUser', { user: current });

        resolve();
      }).catch(() => {
        reject();
      });
    });
  },
  updateForceAccountUpdate({ commit }, forceUpdate) {
    commit('setForceAccountUpdate', forceUpdate);
  }
}

const mutations = {
  changeUser (state, {user}) {
    state.user = user;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  },
  setImpersonationLoading (state, value) {
    state.impersonationLoading = value;
  },
  setTemporaryMemberInfo(state, value) {
    state.temporaryMemberInfo = value;
  },
  setForceAccountUpdate(state, value) {
    state.user.forceAccountUpdate = value;
  },
  setRefererSource(state, value) {
    state.refererSource = value;
  },
  removeToken(state) {
    delete state.user.token;
  },
  setQhid(state, qhid) {
    state.currentMemberQhid = qhid
  }
}

const getters = {
  isInRole: (state) => (role) => {
    return state.user.roles.indexOf(role) > -1;
  },
  temporaryMemberInfo: (state) => {
    return state.temporaryMemberInfo;
  },
  isEmployee: (state) => {
    return state.user?.isEmployee === true;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}