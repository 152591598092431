import axios from "axios";
import headers from '../authHeaders';

export default { 
	async checkBenefitsByCptCode(qhid, cptCode) {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/benefit/getPrecertTextByCPTCode/${qhid}/${cptCode}`,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	}
}
