const mixin = {
  data() {
    return {
      authInternal: {}
    };
  },
  props: {
    id: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    validationContext: {
      type: Object,
      default: null
    }
  },
  methods: {
    onChange(v) {
      v = v || this.$v;

      v.$touch();
      this.syncValidity();
    },
    syncValidity() {
      this.$emit('update:isDirty', this.$v.$dirty);
      this.$emit('update:isValid', !this.$v.$invalid);
    },
    syncValidationContext() {
      this.$emit('update:validationContext', {
        touch: this.onChange
      });
    }
  },
  validations: {
    authInternal: {}
  },
  mounted() {
    this.syncValidity();
    this.syncValidationContext();
  }
};

export default mixin;