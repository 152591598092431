import { CoverageInfo } from "@/services/api/member";


export default function inCoveragePeriod(coverageInfo: CoverageInfo[] | null): (date: Date | string | null | undefined) => boolean {
    return (date) => {
        if (!date || !coverageInfo) { return true }
        const parsedDate = new Date(date)
        const now = new Date()
        const aYearAgo = new Date()
        aYearAgo.setFullYear(now.getFullYear() - 1)
        return coverageInfo.some((info) => {
            return info.planOptionName != "No Plan"
                && info.effectiveDate <= parsedDate && parsedDate < info.termDate
                && parsedDate >= aYearAgo
        })
    }
}

