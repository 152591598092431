import axios from "axios";
import headers from "../authHeaders";

export default {
    async getGroupWideMessage(groupId: number): Promise<string> {
        const response = await axios({
            method: "get",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/group/${groupId}/groupwidemessage`,
            headers: await headers.getAuthHeaders()
        })
        return response.data;
    },
    async getTollFreeNumber(groupId: number): Promise<string> {
        const response = await axios({
            method: "get",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/group/${groupId}/tollfreenumber`,
            headers: await headers.getAuthHeaders()
        })
        return response.data
    },
    async getSecureStatus(groupId: number): Promise<boolean> {
        const response = await axios({
            method: "get",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/group/${groupId}/isSecure`,
            headers: await headers.getAuthHeaders()
        })
        return response.data
    }
}
