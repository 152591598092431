import { mapGetters } from 'vuex';

const mixin = {
  data() {
    return {
      routeBaseName: 'UnlockAccount',
      text: {
        header: 'Unlock Account',
        error: 'Error occurred. Please try again later.',
        locked: 'Contact your Care Coordinators at (800) 247-8956 for assistance.'
      }
    }
  },
  computed: {
    ...mapGetters('unlockAccount', {
      data: 'data',
      currentStep: 'step',
      locked: 'locked',
      staticEmail: 'staticEmail',
      currentMfaMethod: 'mfaMethod'
    })
  }
};

export default mixin;