import axios from "axios";
import headers from '../authHeaders';
import store from '../../store';

export default { 

	loginUser(userName, password) {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/login/',
			data: { 
				'LoginRequest' :
				{
					'UserName': userName,
					'Password': password
				},
				'RefererSource' : {
					'Value': store.state.currentUser.refererSource
				}
			}
		}).then(response => {
			return response.data;
		});
	},

	emailExists(email) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/email/',
			data: { 'Email': email, 'SendEmailMfa': false }
		}).then(response => {
			return response.data;
		});
	},

	register(data) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/register/',
			data: data
		}).then(response => {
			return response.data;
		})
	},

	async updateAccount(data) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/update/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		})
	},

	async updateProfile(data) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/updateProfile/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		})
	},

	async updateUsername(data) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/updateUsername/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		})
	},

	async updatePassword(data) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/updatePassword/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		})
	},

	async updateSecurityQuestions(data) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/updateSecurityQuestions/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		})
	},

	sendMfaEmail(userName, email, flow) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/account/${flow}/sendmfa/`,
			data: { 'UserName': userName, 'Email': email }
		}).then(response => {
			return response.data;
		});
	},

	sendMfaText(userName, mobileNumber, flow) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/account/${flow}/sendmfa/`,
			data: { 'UserName': userName, 'MobileNumber': mobileNumber }
		}).then(response => {
			return response.data;
		});
	},

	verifyMfa(userName, code, flow) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/account/${flow}/verifymfa/`,
			data: { 'UserName': userName, 'Code': code }
		}).then(response => {
			return response.data;
		});
	},

	async updateAccountFromForgotPassword(data) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/updateFromForgotPassword/',
			data: data
		}).then(response => {
			return response.data;
		})
	},

	getForgotPasswordInfo(request) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/getforgotpasswordinfo/',
			data: request
		}).then(response => {
			return response.data;
		});
	},

	submitAnswer(email, enteredAnswer, securityAnswerFormat, flow) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/account/${flow}/submitanswer/`,
			data: { email, enteredAnswer, securityAnswerFormat }
		}).then(response => {
			return response.data;
		});
	},

	async contact(subject, firstName, lastName, phone, email, message) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/contact/',
			data: { 
				'Subject': subject, 
				'FirstName': firstName, 
				'LastName': lastName, 
				'Email': email, 
				'Phone': phone, 
				'Message': message 
			},
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},

	async feedback(rating, reason) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/feedback/',
			data: { 
				rating,
				reason
			},
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},

	async updateTemporaryPassword(request) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/updateTemporaryPassword/',
			headers: await headers.getAuthHeaders(),
			data: request
		}).then(response => {
			return response.data;
		});
	},

	beginUnlockAccount(request) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/unlockaccount/begin/',
			data: request
		}).then(response => {
			return response.data;
		});
	},

	finalizeUnlockAccount(helperId) {
		return axios({
			method: 'POST',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/account/unlockaccount/finalize/${helperId}/`
		}).then(response => {
			return response.data;
		});
	},

	async handshake() {
		return axios({
			method: 'GET',
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/account/handshake/',
			headers: await headers.getAuthHeaders()
		});
	}
}
