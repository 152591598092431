function getMetaValue(route, key) {
  if (route.matched) {
    for (let i = 0; i < route.matched.length; i++) {
      if (route.matched[i].meta && route.matched[i].meta[key]) {
        return route.matched[i].meta[key];
      }
    }
  }

  return undefined;
}

const mixin = {
  computed: {
    hideLayout() {
      return getMetaValue(this.$route, 'hideLayout');
    },
    hideHeader() {
      return getMetaValue(this.$route, 'hideHeader');
    },
    hideFooter() {
      return getMetaValue(this.$route, 'hideFooter');
    },
    disableIdleTimeout() {
      return getMetaValue(this.$route, 'disableIdleTimeout');
    },
    isAdmin() {
      return this.$route.matched.find(x => x.meta.admin);
    }
  }
};

export default mixin;
