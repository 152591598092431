import { mapGetters } from 'vuex';

const mixin = {
  data() {
    const email = "providerservices@quantum-health.com";
    const subject = encodeURIComponent("Forgot Password Help");
    const emailLink = `<a href="mailto:${email}?subject=${subject}">${email}</a>`;
    const locked = `Contact your Care Coordinators at ${emailLink} for assistance.`;

    return {
      supportEmailLink: emailLink,
      routeBaseName: 'ForgotPassword',
      text: {
        locked,
        header: 'Forgot Password',
        error: 'Error occurred. Please try again later.',
      }
    }
  },
  computed: {
    hasError() {
      return this.locked || this.accountLocked;
    },
    ...mapGetters('forgotPassword', {
      data: 'data',
      currentStep: 'step',
      locked: 'locked',
      accountLocked: 'accountLocked',
      currentMfaMethod: 'mfaMethod',
      complete: 'complete'
    })
  },
  mounted() {
    if (this.complete && this.step !== this.currentStep) {
      this.$router.push({ name: `${this.routeBaseName}${this.currentStep}` });
    }
  }
};

export default mixin;