const dialog = {
	methods: {
		confirm({ ok, cancel, title, content, cancelText, okText }) {
			const create = this.$createElement;

			cancel = cancel || (() => {});
			title = title || 'Confirm';
			okText = okText || 'Yes';
			cancelText = cancelText || 'No';
			content = content || 'Are you sure?'

			const body = create('p', {
				domProps: {
					className: 'text-left',
					innerHTML: content
				}
			});

			this.$bvModal
				.msgBoxConfirm(body, {
					id: 'modal-confirm',
					noFade: true,
					hideHeaderClose: false,
					noCloseOnBackdrop: true,
					title: title,
					okTitle: okText,
					cancelTitle: cancelText,
					titleTag: 'h4',
					buttonSize: 'sm',
					dialogClass: 'modal-confirmation',
					headerCloseContent: "<i class='fal fa-times' aria-hidden='true' />"
				})
				.then(value => {
					if (value) {
						ok();
					} else {
						cancel();
					}
				})
				.catch(() => {});
		}
	}
}
export default dialog;