export default { 

    setRememberMeValues(name, values) {
        var cookie = name + '=';
		
		if ( values !== '') {			
			var expires = '';			
            var date = new Date();
            date.setDate(date.getDate() + 60);
            expires = date.toGMTString();

            cookie += values + ';';			
			cookie += 'expires=' + expires + ';';		
		} else {			
			cookie += values + ';';
		}
		
		document.cookie = cookie;
    },

    getRememberMeValues(name) {
        var gCookieVal = document.cookie.split("; ");
		
        for (var i = 0; i < gCookieVal.length; i++) {
            // a name/value pair (a crumb) is separated by an equal sign        
            var gCrumb = gCookieVal[i].split("=");
                        
            if (name === gCrumb[0]) {
                var value = '';
                try {
                    value = gCrumb[1];
                } catch (e) {
                    value = unescape(gCrumb[1]);
                }					
                return value;
            }
        }

        // a cookie with the requested name does not exist
        return null;
    }
}
